import { all, fork } from 'redux-saga/effects';

import geolocator from './geolocator';
import lieuucation from './lieuucation';
import search from './search';
import supportRequest from './supportRequest';
import user from './user';

export function* rootSaga() {
  yield all([
    fork(user),
    fork(search),
    fork(lieuucation),
    fork(geolocator),
    fork(lieuucation),
    fork(supportRequest)
  ]);
}
