import client from '@feathersjs/client';
import { Paginated, Service } from '@feathersjs/feathers';
import router from 'next/router';

import axios from 'axios';

import lodashMerge from 'lodash.merge';

import {
  ILieuucation,
  ILieuucationCbsa,
  ILieuucationTimestamped,
  ILieuucationZip,
  INewPasswordRequest,
  IPasswordResetRequest,
  IReduxHistory,
  ISupportRequest,
  IUser,
  ServicePaths
} from '@lieuu/core';

import { IPlacePredictionsRequest } from '@actions/SearchActions';
import { reduxPersistKey } from '@redux/store';

import { logTimingEnd, logTimingStart, logUserId } from './analytics';

const app = client<ServicePaths>();

const axiosInstance = axios.create({
  timeout: 15000
});

const rest = client.rest('/api');
app.configure(rest.axios(axiosInstance));

app.configure(
  client.authentication({
    path: 'authentication'
  })
);

const devWait = async (): Promise<boolean> => {
  // if (process.env.NODE_ENV === 'development') {
  //   await new Promise((resolve) => setTimeout(resolve, 50));
  // }

  return true;
};

// ROUTER

export const pushHome = async () => router.push('/');

export const pushHomeAuth = async () => router.push('/search');

// USER

export const userService = app.service('v1/user');

userService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        logTimingStart({ service: 'v1/user', method: context.method });
        return context;
      }
    ]
  },
  after: {
    all: [
      async (context) => {
        await devWait();
        logTimingEnd({ service: 'v1/user', method: context.method });
        return context;
      }
    ]
  }
});

export const createUser = async (user: IUser): Promise<IUser> => {
  const newUser = await userService.create(user);
  logUserId(newUser._id);
  return newUser;
};

export const patchUser = async (
  user: Partial<IUser>
): Promise<IUser | null> => {
  console.log({ user });
  const authUser = await app.get('authentication');

  try {
    if (authUser && authUser.user && authUser.user._id) {
      return userService.patch(
        authUser.user._id,
        lodashMerge(authUser.user, user)
      );
    }
  } catch (e) {
    console.error(e);
  }

  return authUser.user;
};

export const getUser = async (): Promise<IUser> => {
  const user = await userService.get(
    (await app.get('authentication')).user._id
  );

  logUserId(user._id);

  return user;
};

export const authenticateUser = async (user?: IUser): Promise<IUser> => {
  let response;

  if (!user) {
    response = await app.reAuthenticate();
  } else {
    response = await app.authenticate({
      strategy: 'local',
      ...user
    });
  }

  logUserId(response.user._id);

  return response.user;
};

export const deleteUser = async (): Promise<void> => {
  const userId = (await app.get('authentication')).user._id;

  try {
    await userService.remove(userId);
  } catch (e) {}

  await logOutUser();
};

export const verifyUser = async (verifyKey: string): Promise<void> => {
  return axiosInstance.post('/api/v1/user/verify', { verifyKey });
};

export const logOutUser = async (): Promise<void> => {
  app.logout();

  if (localStorage) {
    localStorage.removeItem(`persist:${reduxPersistKey}`); // remove redux persist entry
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  const request: IPasswordResetRequest = { email };
  return axiosInstance.post('/api/v1/user/password-reset', request);
};

export const newPassword = async (
  request: INewPasswordRequest
): Promise<void> => {
  return axiosInstance.post('/api/v1/user/new-password', request);
};

// LIEUUCATION

export const lieuucationService: Service<ILieuucation> = app.service(
  'v1/lieuucation'
);
export const zipService: Service<ILieuucationZip> = app.service('v1/zip');
export const cbsaService: Service<ILieuucationCbsa> = app.service('v1/cbsa');

lieuucationService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        logTimingStart({ service: 'v1/lieuucation', method: context.method });
        return context;
      }
    ]
  },
  after: {
    all: [
      async (context) => {
        await devWait();
        logTimingEnd({ service: 'v1/lieuucation', method: context.method });
        return context;
      }
    ]
  }
});

zipService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        logTimingStart({ service: 'v1/zip', method: context.method });
        return context;
      }
    ]
  },
  after: {
    all: [
      async (context) => {
        await devWait();
        logTimingEnd({ service: 'v1/zip', method: context.method });
        return context;
      }
    ]
  }
});

cbsaService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        logTimingStart({ service: 'v1/cbsa', method: context.method });
        return context;
      }
    ]
  },
  after: {
    all: [
      async (context) => {
        await devWait();
        logTimingEnd({ service: 'v1/cbsa', method: context.method });
        return context;
      }
    ]
  }
});

export interface IGetLieuucationRequest {
  id: string;
}

export type IGetResponse<T> = T | T[] | Paginated<T>;

export const getLieuucationById = async ({
  id
}: IGetLieuucationRequest): Promise<
  ILieuucation | ILieuucationZip | undefined
> => {
  const lieuucations: IGetResponse<ILieuucation> = await lieuucationService.find(
    {
      query: {
        internalIds: { $in: [id] },
        version: process.env.MAPBOX_MAP_VERSION,
        $limit: 1
      }
    }
  );

  const lieuucationsArray = getArrayFromFeathersResponse(lieuucations);

  if (lieuucationsArray && lieuucationsArray[0]) {
    return lieuucationsArray[0];
  }

  return undefined;
};

const chunkArray = <T>(array: T[], chunkSize: number) => {
  const chunks: T[][] = [];

  if (array.length <= 0) {
    throw new Error('Array to be chunked was of length zero');
  }

  let currentChunk: T[] = [];
  let count = 0;

  const resetChunk = () => {
    chunks.push(currentChunk);
    count = 0;
    currentChunk = [];
  };

  for (const val of array) {
    if (count >= chunkSize) {
      resetChunk();
    }

    currentChunk.push(val);

    count += 1;
  }

  resetChunk();

  return chunks;
};

const getArrayFromFeathersResponse = <T>(ts: IGetResponse<T>): T[] => {
  if ((ts as T)['_id']) {
    return [ts as T];
  }
  if ((ts as T[]).length && (ts as T[]).length > 0) {
    return ts as T[];
  }
  if ((ts as Paginated<T>).total && (ts as Paginated<T>).total > 0) {
    return (ts as Paginated<T>).data;
  }

  return [];
};

export const getLieuucations = async (
  lieuucations: ILieuucationTimestamped[]
): Promise<ILieuucation[]> => {
  if (!lieuucations || lieuucations.length === 0) {
    return [];
  }

  const lieuucationsChunked = chunkArray(lieuucations, 15);

  const retrievedLieuucations = await Promise.all(
    lieuucationsChunked.flatMap((chunk) =>
      lieuucationService.find({
        query: {
          internalIds: { $in: chunk.map((e) => e.internalId) },
          version: process.env.MAPBOX_MAP_VERSION
        }
      })
    )
  );

  return getArrayFromFeathersResponse(
    retrievedLieuucations.reduce(
      (prev, curr) => [
        ...getArrayFromFeathersResponse(prev),
        ...getArrayFromFeathersResponse(curr)
      ],
      []
    )
  );
};

// REDUX HISTORY

export const reduxHistoryService: Service<IReduxHistory> = app.service(
  'v1/redux-history'
);

reduxHistoryService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        return context;
      }
    ]
  }
});

export const createReduxHistory = async (
  history: IReduxHistory
): Promise<IReduxHistory> => reduxHistoryService.create(history);

// GEOLOCATION

export const getCurrentLocation = async (): Promise<Coordinates> => {
  await devWait();

  return new Promise((resolve, reject) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => coords && resolve(coords),
        (error) => reject(error)
      );
    } else {
      reject();
    }
  });
};

// GOOGLE MAPS

export const getPlacePredictions = async ({
  input,
  location
}: IPlacePredictionsRequest): Promise<google.maps.places.AutocompletePrediction[]> => {
  await devWait();

  return new Promise((resolve, reject) => {
    if (!input) {
      resolve([]);
    }

    const autocompleteService = new google.maps.places.AutocompleteService();

    const params = location
      ? {
          radius: 35000,
          location: new google.maps.LatLng(
            location.latitude,
            location.longitude
          )
        }
      : {};

    autocompleteService.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: 'USA' },
        ...params
      },
      (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error(`Google Maps PlacesServiceStatus was ${status}`));
          return;
        }

        resolve(predictions);
      }
    );
  });
};

export const getPlaceForQuery = async (
  input: string
): Promise<google.maps.GeocoderResult> => {
  await devWait();

  return new Promise((resolve, reject) => {
    if (!input) {
      resolve(undefined);
    }

    const geocoderService = new google.maps.Geocoder();

    geocoderService.geocode({ address: input }, (result, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        reject(new Error(`Google Maps GeocoderStatus was ${status}`));
        return;
      }

      if (result.length < 1) {
        reject(new Error(`No results from geocoding.`));
        return;
      }

      resolve(result[0]);
    });
  });
};

// SUPPORT REQUEST

export const supportRequestService = app.service('v1/support-request');

supportRequestService.hooks({
  before: {
    all: [
      async (context) => {
        await devWait();
        return context;
      }
    ]
  }
});

export const createSupportRequest = async (
  supportRequest: ISupportRequest
): Promise<ISupportRequest> => supportRequestService.create(supportRequest);

export { app, router };
