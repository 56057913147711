/**
 * Lieuu (https://www.lieuu.com/)
 */
import * as React from 'react';

import {
  createStyles,
  Grid,
  IconButton,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';

import classnames from 'classnames';

import Link from '@components/Link';
import { useRouter } from 'next/router';

const styles = (theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer'
    },
    layout: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      maxWidth: theme.breakpoints.values.md
    },
    layoutLieuucationReport: {
      paddingRight: theme.spacing(30),

      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(7)
      }
    },
    footer: {
      marginTop: theme.spacing(4),
      borderTop: `2px solid ${theme.palette.divider}`
    },
    copyright: {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center'
    },
    copyrightIcon: {
      marginLeft: theme.spacing(1)
    }
  });

interface IProps extends React.Props<{}> {}

const Footer: React.FC<IProps & WithStyles<typeof styles>> = ({ classes }) => {
  const { pathname } = useRouter();
  const footers = [
    {
      title: 'Company',
      description: [
        { text: 'Home', href: '/' },
        { text: 'About', href: '/about' },
        { text: 'Contact', href: '/contact' }
      ]
    },
    {
      title: 'Product',
      description: [
        {
          text: 'Sample Report',
          href: '/sample-report'
        },
        { text: 'Map', href: '/map' }
        // { text: 'Pricing', href: '/pricing' }
      ]
    },
    {
      title: 'Support',
      description: [
        { text: 'Help', href: '/support' },
        { text: 'Feedback', href: '/feedback' }
      ]
    },
    {
      title: 'Legal',
      description: [
        { text: 'Privacy Policy', href: '/privacy' },
        { text: 'Terms of Use', href: '/terms' }
      ]
    }
  ];

  return (
    <div
      className={classnames(
        (pathname.includes('/micromarket') || pathname === '/sample-report') &&
          classes.layoutLieuucationReport
      )}
    >
      <footer className={classnames(classes.footer, classes.layout)}>
        <div>
          <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  {footer.title}
                </Typography>
                {footer.description.map((item) => (
                  <Link key={item.text} href={item.href}>
                    <Typography
                      className={classes.link}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {item.text}
                    </Typography>
                  </Link>
                ))}
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={classes.copyright}>
          <Typography variant="subtitle2" color="textPrimary">
            © {new Date().getFullYear()} Lieuu, LLC. All rights reserved.
          </Typography>
          <div className={classes.copyrightIcon}>
            <Link href="https://www.linkedin.com/company/lieuu">
              <IconButton size="small" aria-label="linkedin">
                <LinkedIn />
              </IconButton>
            </Link>
            <Link href="https://www.facebook.com/lieuuapp">
              <IconButton size="small" aria-label="facebook">
                <Facebook />
              </IconButton>
            </Link>
            <Link href="https://www.instagram.com/lieuu_app">
              <IconButton size="small" aria-label="instagram">
                <Instagram />
              </IconButton>
            </Link>
            <Link href="https://twitter.com/lieuu_app">
              <IconButton size="small" aria-label="twitter">
                <Twitter />
              </IconButton>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withStyles(styles)(Footer);
