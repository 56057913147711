import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeLatest
} from 'redux-saga/effects';

import * as GeolocatorActions from '@actions/GeolocatorActions';
import * as UserActions from '@actions/UserActions';

import * as api from '@api';
import { logEvent } from '@api/analytics';

export function* getCurrentPosition() {
  try {
    const loc = yield race({
      currentLocation: call(api.getCurrentLocation),
      timeout: delay(10000)
    });

    if (loc && loc.currentLocation) {
      logEvent({ category: 'Map', action: 'Geolocator Successful' });

      yield put(
        UserActions.patchUser.request({
          coordinates: [
            {
              lat: loc.currentLocation.latitude,
              lon: loc.currentLocation.longitude
            }
          ]
        })
      );
      yield put(
        GeolocatorActions.getCurrentPosition.success(loc.currentLocation)
      );
    } else {
      yield put(
        GeolocatorActions.getCurrentPosition.failure(
          new Error('Timed out getting user position.')
        )
      );
    }
  } catch (error) {
    yield put(GeolocatorActions.getCurrentPosition.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchGetCurrentPosition() {
  yield takeLatest(
    GeolocatorActions.getCurrentPosition.request,
    getCurrentPosition
  );
}

export default function* root() {
  yield all([fork(watchGetCurrentPosition)]);
}
