import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import * as LieuucationActions from '@actions/LieuucationActions';
import * as SnackbarActions from '@actions/SnackbarActions';
import * as api from '@api';

import { ILieuucation, ILieuucationTimestamped } from '@lieuu/core';

export function* getAllLieuucations(request: {
  payload: ILieuucationTimestamped[];
}) {
  try {
    const lieuucations: ILieuucation[] = yield call(
      api.getLieuucations,
      request.payload
    );
    yield put(LieuucationActions.getLieuucations.success(lieuucations));
  } catch (error) {
    yield put(SnackbarActions.showSnackbar(error));
    yield put(LieuucationActions.getLieuucations.failure(error));
  }
}

export function* getLieuucationById(request) {
  try {
    const lieuucation = yield call(api.getLieuucationById, request.payload);
    if (lieuucation) {
      yield put(LieuucationActions.getLieuucationById.success(lieuucation));
    } else {
      throw new Error(
        `Could not find internalId/version combination: ${JSON.stringify(
          request.payload
        )}`
      );
    }
  } catch (error) {
    yield put(SnackbarActions.showSnackbar(error));
    yield put(LieuucationActions.getLieuucationById.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchGetAllLieuucations() {
  yield takeLatest(
    LieuucationActions.getLieuucations.request,
    getAllLieuucations
  );
}

export function* watchGetLieuucationById() {
  yield takeEvery(
    LieuucationActions.getLieuucationById.request,
    getLieuucationById
  );
}

export default function* root() {
  yield all([fork(watchGetAllLieuucations), fork(watchGetLieuucationById)]);
}
