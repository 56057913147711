import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as SearchActions from '@actions/SearchActions';
import * as SnackbarActions from '@actions/SnackbarActions';
import * as api from '@api';

export function* getPlacePredictions(request) {
  try {
    const predictions = yield call(api.getPlacePredictions, request.payload);
    yield put(SearchActions.getPlacePredictions.success(predictions));
  } catch (error) {
    yield put(SnackbarActions.showSnackbar(error));
    yield put(SearchActions.getPlacePredictions.failure(error));
  }
}

export function* getPlaceForQuery(request) {
  try {
    const result: google.maps.GeocoderResult = yield call(
      api.getPlaceForQuery,
      request.payload
    );

    yield put(SearchActions.getPlaceForQuery.success(result));
  } catch (error) {
    yield put(SnackbarActions.showSnackbar(error));
    yield put(SearchActions.getPlaceForQuery.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchGetPlacePredictions() {
  yield takeLatest(
    SearchActions.getPlacePredictions.request,
    getPlacePredictions
  );
}

export function* watchGetPlaceForQuery() {
  yield takeLatest(SearchActions.getPlaceForQuery.request, getPlaceForQuery);
}

export default function* root() {
  yield all([fork(watchGetPlacePredictions), fork(watchGetPlaceForQuery)]);
}
